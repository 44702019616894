<template>
    <div>
        <transition name="fade">
        <LoginForm />
        </transition>
    </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default{
    components: {LoginForm},
    data() {
        return {
            
        }
    },
    beforeCreate() {
        this.$store.state.connected = false;

    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
