<template>
  <div style="margin:5px;" class="rowTitle">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
        {{lang.additional_information}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body> 
          <b-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <table id="Table_01" width="272" height="243" class="drawTable" cellpadding="0" cellspacing="0">
                  <tr>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_0`" :src="`${publicPath}vcar-ac_01.png`" @click="changeMe(0,$event)" width="87" height="11" alt=""></td>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_8`" :src="`${publicPath}vcar-ac_02.png`" @click="changeMe(8,$event)" width="104" height="11" alt=""></td>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_16`" @click="changeMe(16,$event)" :src="`${publicPath}vcar-ac_03.png`" width="80" height="11" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="11" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="2">
                      <img :id="`click`+cardid+`_1`" :src="`${publicPath}vcar-ac_04.png`" @click="changeMe(1,$event)" width="87" height="64" alt=""></td>
                    <td>
                      <img :id="`click`+cardid+`_9`" @click="changeMe(9,$event)" :src="`${publicPath}vcar-ac_05.png`" width="49" height="17" alt=""></td>
                    <td>
                      <img :id="`click`+cardid+`_10`" @click="changeMe(10,$event)" :src="`${publicPath}vcar-ac_06.png`" width="55" height="17" alt=""></td>
                    <td colspan="2" rowspan="2">
                      <img :id="`click`+cardid+`_17`" @click="changeMe(17,$event)" :src="`${publicPath}vcar-ac_07.png`" width="80" height="64" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="17" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_11`" @click="changeMe(11,$event)" :src="`${publicPath}vcar-ac_08.png`" width="104" height="47" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="47" alt=""></td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <img :id="`click`+cardid+`_2`" :src="`${publicPath}vcar-ac_09.png`" @click="changeMe(2,$event)" width="50" height="64" alt=""></td>
                    <td rowspan="2">
                      <img :id="`click`+cardid+`_3`" :src="`${publicPath}vcar-ac_10.png`" @click="changeMe(3,$event)" width="37" height="64" alt=""></td>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_12`" @click="changeMe(12,$event)" :src="`${publicPath}vcar-ac_11.png`" width="104" height="42" alt=""></td>
                    <td rowspan="3">
                      <img :id="`click`+cardid+`_18`" @click="changeMe(18,$event)" :src="`${publicPath}vcar-ac_12.png`" width="33" height="66" alt=""></td>
                    <td rowspan="2">
                      <img :id="`click`+cardid+`_19`" @click="changeMe(19,$event)" :src="`${publicPath}vcar-ac_13.png`" width="47" height="64" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="42" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="3">
                      <img :id="`click`+cardid+`_13`" @click="changeMe(13,$event)" :src="`${publicPath}vcar-ac_14.png`" width="104" height="57" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="22" alt=""></td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      <img :id="`click`+cardid+`_4`" :src="`${publicPath}vcar-ac_15.png`" @click="changeMe(4,$event)" width="50" height="62" alt=""></td>
                    <td rowspan="3">
                      <img :id="`click`+cardid+`_5`" :src="`${publicPath}vcar-ac_16.png`" @click="changeMe(5,$event)" width="37" height="62" alt=""></td>
                    <td rowspan="3">
                      <img :id="`click`+cardid+`_20`" @click="changeMe(20,$event)" :src="`${publicPath}vcar-ac_17.png`" width="47" height="62" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="2" alt=""></td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <img :id="`click`+cardid+`_21`" @click="changeMe(21,$event)" :src="`${publicPath}vcar-ac_18.png`" width="33" height="60" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="33" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_14`" @click="changeMe(14,$event)" :src="`${publicPath}vcar-ac_19.png`" width="104" height="27" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="27" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_6`" :src="`${publicPath}vcar-ac_20.png`" @click="changeMe(6,$event)" width="87" height="27" alt=""></td>
                    <td colspan="2" rowspan="2">
                      <img :id="`click`+cardid+`_15`" @click="changeMe(15,$event)" :src="`${publicPath}vcar-ac_21.png`" width="104" height="42" alt=""></td>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_22`" @click="changeMe(22,$event)" :src="`${publicPath}vcar-ac_22.png`" width="80" height="27" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="27" alt=""></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_7`" :src="`${publicPath}vcar-ac_23.png`" @click="changeMe(7,$event)" width="87" height="15" alt=""></td>
                    <td colspan="2">
                      <img :id="`click`+cardid+`_23`" @click="changeMe(23,$event)" :src="`${publicPath}vcar-ac_24.png`" width="80" height="15" alt=""></td>
                    <td>
                      <img :src="`${publicPath}spacer.gif`" width="1" height="15" alt=""></td>
                  </tr>
                </table>
              </v-col>

            </v-row>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {  },
  name: 'CVAdditionalRow',
  
  data () {
    return {
      publicPath: process.env.BASE_URL+'vcar/',
      publicPathv: process.env.BASE_URL,
      currSrc: [
          '/vcar/vcar-ac_01.png',
          '/vcar/vcar-ac_04.png',
          '/vcar/vcar-ac_09.png',
          '/vcar/vcar-ac_10.png',
          '/vcar/vcar-ac_15.png',
          '/vcar/vcar-ac_16.png',
          '/vcar/vcar-ac_20.png',
          '/vcar/vcar-ac_23.png',
          '/vcar/vcar-ac_02.png',
          '/vcar/vcar-ac_05.png',
          '/vcar/vcar-ac_06.png',
          '/vcar/vcar-ac_08.png',
          '/vcar/vcar-ac_11.png',
          '/vcar/vcar-ac_14.png',
          '/vcar/vcar-ac_19.png',
          '/vcar/vcar-ac_21.png',
          '/vcar/vcar-ac_03.png',
          '/vcar/vcar-ac_07.png',
          '/vcar/vcar-ac_12.png',
          '/vcar/vcar-ac_13.png',
          '/vcar/vcar-ac_17.png',
          '/vcar/vcar-ac_18.png',
          '/vcar/vcar-ac_22.png',
          '/vcar/vcar-ac_24.png',
      ],
      selSrc: [
        '/vcar/vcar-ac-sel_01.png',
        '/vcar/vcar-ac-sel_04.png',
        '/vcar/vcar-ac-sel_09.png',
        '/vcar/vcar-ac-sel_10.png',
        '/vcar/vcar-ac-sel_15.png',
        '/vcar/vcar-ac-sel_16.png',
        '/vcar/vcar-ac-sel_20.png',
        '/vcar/vcar-ac-sel_23.png',
        '/vcar/vcar-ac-sel_02.png',
        '/vcar/vcar-ac-sel_05.png',
        '/vcar/vcar-ac-sel_06.png',
        '/vcar/vcar-ac-sel_08.png',
        '/vcar/vcar-ac-sel_11.png',
        '/vcar/vcar-ac-sel_14.png',
        '/vcar/vcar-ac-sel_19.png',
        '/vcar/vcar-ac-sel_21.png',
        '/vcar/vcar-ac-sel_03.png',
        '/vcar/vcar-ac-sel_07.png',
        '/vcar/vcar-ac-sel_12.png',
        '/vcar/vcar-ac-sel_13.png',
        '/vcar/vcar-ac-sel_17.png',
        '/vcar/vcar-ac-sel_18.png',
        '/vcar/vcar-ac-sel_22.png',
        '/vcar/vcar-ac-sel_24.png',
      ],
      clicked:[],
      cardid: 0,
      addition:{
        scrachnotes: ''
      },
      clickedp: [],
      card: [],
    }
  },
  mounted(){
    //this.cardid = this.$route.params.cardid;
    
  },
  created(){
    setInterval(() => {
      // this.getCard();
     // this.doSelect();
    },1000);
    
  },
  computed: {
    lang: function(){
        if(this.$cookies.get(this.$store.state.LangCooki) != null){
            if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
            return this.$store.state.lang.en;
            }else{
            return this.$store.state.lang.ar;
            }
        }
        else if(this.$store.state.deflang == 'en'){
            return this.$store.state.lang.en;
        }else{
            return this.$store.state.lang.ar;
        }
    },
  },
  methods:{
    updateCard(){
      this.$parent.$parent.scrachnotes = this.addition.scrachnotes;
    },
    getCard(){
      let tcardid = this.$route.params.cardid;
      if(typeof tcardid === 'undefined'){
        return false;
      }
      this.cardid = tcardid;
      let post = new FormData();
      post.append('type' , 'getCards');
      const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
      post.append('auth',auth);
      post.append('data[cardid]',this.cardid)
      axios.post(
        this.$store.state.SAMCOTEC.r_path,
        post
      ).then((response) => {
        const res = response.data;
        
        this.addition.scrachnotes = res.results.data[0].scrachnotes;
        const cl = res.results.data[0].clicked;
        const clt = cl.split(',');
        for(let i = 0;i<clt.length;i++){ 
          this.clicked.push(parseInt(clt[i])); 
        }
        
      })
    },
    changeMe(id,event)
    {
      // console.log(this.$parent.$parent.card);
      // if(window.location.origin+this.currSrc[id] == event.path[0].src){
      //   document.getElementById(event.path[0].id).src = this.selSrc[id];
      //   this.clicked.push(id);
      // }else{
      //   document.getElementById(event.path[0].id).src = this.currSrc[id]
      //   const ind = this.clicked.indexOf(id);
      //   if(ind > -1) this.clicked.splice(ind,1);
      // }
      // this.getData(this.clicked);
      // this.saveSelect();
    },
    doSelect(){
      const g = this.clicked;
      
      // for(let i=0;i<this.selSrc.length;i++){})
      for(let i = 0;i< this.clicked.length;i++){
        console.log("hilok",this.clicked[i]);
        if(String(document.getElementById('click'+this.cardid+'_'+this.clicked[i])) != 'null'){
          document.getElementById('click'+this.cardid+'_'+String(this.clicked[i])).src = String(window.location.origin+this.selSrc[String(this.clicked[i])]);
        }else{
          document.getElementById('click'+this.cardid+'_'+String(this.clicked[i])).src = String(window.location.origin+this.currSrc[String(this.clicked[i])]);
        }
      }
      // this.getData(this.clicked);
      
    },
    saveSelect()
    {
      // if(typeof this.cardid !== 'undefined'){
      //   const post = new FormData();
      //   post.append('type','saveCard');
      //   post.append('data[cardid]',this.cardid);
      //   post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      //   post.append('data[clicked]',this.clicked);
      //   post.append('data[close]',2);
      //   axios.post(
      //     this.$SAMCOTEC.r_path, post
      //     ).then((response) => {
      //       //
            
      //     })
      // }
    },
    getData(clicked){
      if(typeof this.$parent.$parent.card === 'undefined') return false;
        this.$parent.$parent.card.clicked = clicked;
    }
  }
}
</script>
<style>
.drawTable{
  direction: ltr !important;
  padding:0 !important;
  margin:0 !important;
  border-spacing: 0px !important;
  border: none !important;
}
.drawTable td{
  padding:0 !important;
  margin:0 !important;
  border: none !important;
}
</style>